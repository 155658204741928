.chakra-linkbox__overlay.static-graph {
  position: relative;
}

.chakra-linkbox__overlay.static-graph:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 102%;
  height: 90%;
  background-color: rgb(255 255 255 / 80%);
  z-index: 1;
  content: '';
}
