.nestable-plugin .nestable-list ol li,
.nestable-plugin .nestable-list ol {
  margin-top: 0px;
}

.nestable-plugin > ol > li > .nestable-item-name > .rel.actual {
  border: none;
}

/* .nestable-list .nestable-item--with-children > .nestable-item-name .rel.actual {
  border: none
} */
/* .nestable-list ol li {
  border-top: 1px solid #ddd
} */

/* .nestable-list .nestable-item-name .actual, .nestable-list .nestable-item-name .virtual {
  border-left: 5px solid #8375bd
} */

.nestable-list .nestable-item-name > div {
  border-left: 5px solid #8375bd;
  border-top: 1px solid #ddd;
}

.nestable-list .nestable-item-name > .rel.actual {
  border-left: none;
}

.nestable-plugin > ol > li > .nestable-item-name > .rel {
  margin-top: 15px;
  padding: 12px;
}

.rel {
  height: 45px;
  overflow: hidden;
}

.nestable-list .nestable-item-name > .combi {
  border-left: none;
  padding: 12px;
}

.nestable-combi .nestable-item-name > .combi {
  height: 55px;
}

.nestable-combi .nestable-item-name > div {
  height: 40px;
}

.base .nestable-item-name > div > div {
  max-width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.base.nestable .nestable-list {
  padding: 0 0 0 30px;
}

.base.nestable > .nestable-list {
  padding: 0;
}

.base .nestable-list .nestable-item-name > .combi,
.base
  .nestable-list
  .nestable-item--with-children
  > .nestable-item-name
  > .rel {
  margin-top: 10px;
}
